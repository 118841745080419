<template>
  <div class="container">
    <service-edit-form
        payload-process="create"
        :detailed-services-group="detailedServicesGroup"
    />
  </div>
</template>

<script>
import ServiceEditForm from "@/components/baseServices/serviceEditForm";
export default {
  name: "ServiceCreate",
  components: {ServiceEditForm},
  data() {
    return {
      detailedServicesGroup: {
        name: "",
        description: "",
        users_ids: [],
        services_ids: []
      }
    };
  },
}
</script>

<style scoped>

</style>